import Axios from "axios";

export const getBlogApi = (pageNumber) => {
  const BlogUrl = process.env.REACT_APP_BACKEND_URL + `blog/read/${pageNumber}`;
  const headers = {
    "Content-Type": "application/json",
    // 'Authorization': `${token}`,
  };
  return Axios.get(BlogUrl, { headers: headers });
};

export const getBlogPaginationApi = (pageLimit, pagePerLimit) => {
  const BlogPaginationUrl =
    process.env.REACT_APP_BACKEND_URL + `siteblogs/paginationblog`;
  const data = {
    limit: pageLimit,
    page: pagePerLimit,
  };
  const headers = {
    "Content-Type": "application/json",
    // 'Authorization': `${token}`,
  };
  return Axios.post(BlogPaginationUrl, data, { headers: headers });
};

export const postBlogDetailApi = (slugName) => {
  const BlogDetailUrl =
    process.env.REACT_APP_BACKEND_URL + `blog/view/${slugName}`;
  const headers = {
    "Content-Type": "application/json",
  };
  return Axios.get(BlogDetailUrl, { headers: headers });
};
