import { useEffect } from "react";
import { Helmet } from "react-helmet";
import Aos from "aos";
import "aos/dist/aos.css";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import AboutImage from "../assets/image/group-63@2x.png";
import AboutImage1 from "../assets/image/group-66@2x.png";
import AboutImage2 from "../assets/image/group-69@2x.png";
import GroupImage2 from "../assets/image/Group (2).svg";
import GroupImage1 from "../assets/image/Group (1).svg";
import GroupImage from "../assets/image/Group.svg";
import AboutDetails from "../components/AboutDetails/AboutDetails";
import AboutExperience from "../components/AboutDetails/AboutExperience";

function About() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div class="Layout-module--root--1cm32">
      <Helmet>
        <title>About | Creensight</title>
        <meta name="description" content="About us - At Creensight, we believe in serving best to clients and 
        build trust by working for long-term resulting in good business relationships." charSet="utf-8"/>
        <meta property="og:locale" content="en_US"/>
        <meta property="og:type" content="article"/>
        <meta property="og:title" content="About | Creensight"/>
        <meta property="og:description" content="About us - At Creensight, we believe in serving best to clients and 
        build trust by working for long-term resulting in good business relationships."/>
        <meta property="og:url" content="https://creensight.com/about"/>
        <meta property="og:site_name" content="Creensight"/>
        <meta property="article:modified_time" content="2024-03-22T05:34:02+00:00"/>
        <meta name="twitter:card" content="summary_large_image"/>
        <link rel="canonical" href="https://creensight.com/about" />
      </Helmet>
      <Navbar />
      <div
        className="PageHeader-module--container--AazU9"
        data-aos="fade-down"
        data-test="pageHeader"
      >
        <div
          style={{
            textAlign: "center",
            marginTop: "120px",
            maxWidth: "50%",
            alignSelf: "center",
          }}
        >
          <h2 data-test="pageHeaderTitle">About Creensight</h2>
          <p
            className="PageHeader-module--description--3WdNQ"
            style={{ color: "grey", fontSize: "18px", padding: "15px" }}
            data-test="pageHeaderDescription"
          >
            At Creensight, we believe in serving best to clients and build trust
            by working for long-term resulting in a good business relationships.
          </p>
        </div>
        <div>
          <img src="" alt="" />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          flexWrap: "wrap",
          marginTop: "40px",
        }}
      >
        <AboutExperience imageSrc={GroupImage2} text={"YEARS OF EXPERIENCE"} />
        <AboutExperience imageSrc={GroupImage1} text={"INDEPENDENT PROJECTS"} />
        <AboutExperience imageSrc={GroupImage} text={"TEAM PROJECTS"} />
      </div>
      <div>
        <AboutDetails
          imageSrc={AboutImage}
          title={"COMPANY OVERVIEW"}
          description={
            "Creensight Is A Global IT Solution Provider Led By Experts Who Have Worked Hard To Serve In The Field Of Technology."
          }
        />
        <AboutDetails
          isImageFloatRight="true"
          imageSrc={AboutImage1}
          title={"CORE VALUES"}
          description={
            "We Work Hard To Provide Products That Make Measurable Changes To Anyone’s Business Process."
          }
        />
        <AboutDetails
          imageSrc={AboutImage2}
          title={"OUR VISION"}
          description={
            "Our Vision Is To Serve Society With Ultimate Tech Solutions And Ease Up The Lifestyle By Innovating And Building A Productive Environment For People Around The World."
          }
        />
      </div>
      <Footer />
    </div>
  );
}

export default About;
