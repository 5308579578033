import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Aos from "aos";
import "aos/dist/aos.css";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";
import { getBlogApi } from "../services/blogs/blogService";

function Blog() {
  const navigate = useNavigate();

  const [blogs, setBlogs] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    Aos.init();

    getBlogApi(pageNumber).then((res) => {
      if (res.status === 200) {
        if (pageNumber === 1) {
          setPageCount(Math.ceil(res.data.count / 4));
        }
        setBlogs([...res.data.data]);
      }
    });
  }, [pageNumber]);

  const handleBlogDetailPage = (event, element) => {
    navigate(`/blog-detail/${element.slug}`);
  };

  return (
    // <div id="___gatsby">
    // <div style={{ outline: 'none' }} tabindex="-1" id="gatsby-focus-wrapper">
    <div class="Layout-module--root--1cm32">
      <Helmet>
        <title>Blogs | Creensight</title>
        <meta
          name="description"
          content="Creensight blog is a place where you can find business and technology articles. Check out to get detailed knowledge from the industry experts."
          charSet="utf-8"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Blogs | Creensight" />
        <meta
          property="og:description"
          content="Creensight blog is a place where you can find business and technology articles. Check out to get detailed knowledge from the industry experts."
        />
        <meta property="og:url" content="https://creensight.com/blogs" />
        <meta property="og:site_name" content="Creensight" />
        <meta
          property="article:modified_time"
          content="2024-03-22T05:34:02+00:00"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href="https://creensight.com/blogs" />
      </Helmet>
      <Navbar />
      <div
        class="PageHeader-module--container--AazU9"
        data-aos="fade-up"
        data-test="pageHeader"
      >
        <div class="PageHeader-module--root--IVizT">
          <h2 data-test="pageHeaderTitle">Our Blogs</h2>
          <p
            class="PageHeader-module--description--3WdNQ"
            data-test="pageHeaderDescription"
          >
            A collection of our footprints, stories, remarkable tips, and design
            insights for the others
          </p>
        </div>
      </div>
      <div class="BlogList-module--container--2Dcev">
        <div class="BlogList-module--blogRow--eTtYR">
          {blogs.map((ele) => (
            <a
              class="BlogSnippet-module--snippetContainer--28TDN"
              data-aos="fade-up"
              data-aos-delay="300"
              href
              onClick={(event) => handleBlogDetailPage(event, ele)}
            >
              <div class="BlogSnippet-module--snippetImg--24gmC">
                <div
                  class="BlogSnippet-module--snippetImg--24gmC gatsby-image-wrapper"
                  style={{ position: "relative", overflow: "hidden" }}
                >
                  <div
                    aria-hidden="true"
                    style={{
                      width: "100%",
                      paddingBottom: "52.516666666666666%",
                    }}
                  ></div>
                  <img
                    src={process.env.REACT_APP_BACKEND_URL + `${ele.image}`}
                    alt="testing"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      objectPosition: "center",
                      opacity: 1,
                      transitionDelay: "500ms",
                    }}
                  />
                </div>
              </div>
              <div class="BlogSnippet-module--snippetInfo--2tuio BlogSnippet-module--snippetWithImage--1eBjb">
                <div class="BlogSnippet-module--snippetHeading--1V0cI">
                  <h3>{ele.title}</h3>
                </div>
                <p class="BlogSnippet-module--snippetPublished--2gF0v">
                  <span class="BlogSnippet-module--createdTime--1zYIi">
                    {ele.date}
                  </span>
                  {/* <span class="BlogSnippet-module--snippetReadTime--2_Ol3">3 min read</span> */}
                </p>
              </div>
            </a>
          ))}
        </div>
      </div>
      <div class="BlogPagination-module--root--y2CKh">
        <Pagination
          count={pageCount}
          color="primary"
          onChange={(event, value) => setPageNumber(value)}
        />
      </div>
      <Footer />
    </div>
  );
}

export default Blog;
