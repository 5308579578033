import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import Facebook from "../../assets/image/104498_facebook_icon.svg";
import Twitter from "../../assets/image/icons8-twitter.svg";
import Instagram from "../../assets/image/1161953_instagram_icon.svg";
import Linkedin from "../../assets/image/104493_linkedin_icon.svg";
import "./Footer.css";

function Footer() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <footer
      className="Footer-module--root--i1jWa Footer-module--homePage--16Kvc"
      data-aos="fade"
      data-aos-delay="300"
      data-aos-offset="10"
    >
      <div className="Footer-module--topRow--fXRkx Footer-module--homePageTopRow--3iJBg">
        <div className="Footer-module--copyrightContainer--1TjvE">
          <div className="Copyright-module--container--3WhFS">
            <div className="Copyright-module--copyRight--2SVm4">
              <div className="Copyright-module--socialContainer--2SPOm">
                {/* <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/company"
                >
                  <img
                    src={Linkedin}
                    className="social_media_width"
                    title="Linkedin"
                    alt="Linkedin"
                  />
                </a> */}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://x.com/creensight"
                >
                  <img
                    src={Twitter}
                    className="social_media_width"
                    title="Twitter"
                    alt="Twitter"
                  />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/CreenSight/"
                >
                  <img
                    src={Facebook}
                    className="social_media_width"
                    title="Facebook"
                    alt="Facebook"
                  />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/creensight/"
                >
                  <img
                    src={Instagram}
                    className="social_media_width"
                    title="Instagram"
                    alt="Instagram"
                  />
                </a>
              </div>
              <div>
                <p>Copyright ©2024 Creensight. All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
