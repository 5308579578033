import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Home';
import Blogs from './Blogs';
import About from './About';
import Contact from './Contact';
import Services from "./Services";
// import Portfolio from "./Portfolio";
import BlogDetailPage from "./BlogDetailPage";

const Router = () => {
    return (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/blogs" element={<Blogs />}></Route>
            <Route path="/about" element={<About />}></Route>
            <Route path="/contactus" element={<Contact />}></Route>
            <Route path="/services" element={<Services />}></Route>
            {/* <Route path="/portfolio" element={<Portfolio />}></Route> */}
            <Route path="/blog-detail/:slug" element={<BlogDetailPage />}></Route>
        </Routes>
    </BrowserRouter>
    );
}

export default Router;