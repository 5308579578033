import { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import './AboutDetails.css';

function AboutExperience({ imageSrc, text }) {

    useEffect(() => {
        Aos.init();
    },[])

    return (
        <div>
            <div class="frame-parent" data-aos="fade-left">
                <div class="component-parent">
                    <div class="group-parent3" data-animate-on-scroll>
                        <img class="component-child" alt='groups'
                            src={imageSrc} />
                        <div class="text-here">
                            <h4>{text}</h4>
                        </div>
                        <div class="react-makes-it-container">
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AboutExperience;