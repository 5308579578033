import { useEffect } from "react";
import { Helmet } from "react-helmet";
import Aos from "aos";
import "aos/dist/aos.css";
import Footer from "../components/Footer/Footer";
import Header from "../components/Headers/Header";
import Navbar from "../components/Navbar/Navbar";
import TechnologySection from "../components/TechnologySection/TechnologySection";

function Home() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    // <div id="___gatsby">
    // <div style={{ outline: 'none' }} tabindex="-1" id="gatsby-focus-wrapper">
    <div class="Layout-module--root--1cm32">
      <Helmet>
        <title>Home | Creensight</title>
        <meta name="description" content="Creating scalable web applications is our passion 
        and we strive to offer top-notch applications being top freelance web development company." charSet="utf-8"/>
        <meta property="og:locale" content="en_US"/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content="Home | Creensight"/>
        <meta property="og:description" content="Creating scalable web applications is our passion and we strive to offer top-notch applications being top freelance web development company."/>
        <meta property="og:url" content="https://creensight.com/"/>
        <meta property="og:site_name" content="Creensight"/>
        <meta property="article:modified_time" content="2024-03-22T05:34:02+00:00"/>
        <meta name="twitter:card" content="summary_large_image"/>
        <link rel="canonical" href="https://creensight.com/" />
      </Helmet>
      <Navbar />
      <div class="PageBackgroundImage-module--staticOne--nFHJl">
        <div class="Home-module--root--2hRUr">
          <Header />
          <TechnologySection />
          <div
            className="Home-module--home_footer--oBSVc"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <Footer />
          </div>
        </div>
      </div>
    </div>
    // </div>
    // {/* <div id="gatsby-announcer" style={{ position: 'absolute', top: 0, width: '1px', height: '1px', padding: 0, overflow: 'hidden', clip: 'rect(0, 0, 0, 0)', whiteSpace: 'nowrap', border: 0 }} ariaLive="assertive" ariaAtomic="true"></div> */}
    // </div>
  );
}

export default Home;
