import Router from './pages/Routes';
import './App.css';
import './assets/css/components.css';
import AnimatedCursor from './components/AnimatedCursor/AnimatedCursor';

function App() {
  return (
    <>
      <AnimatedCursor/>
      <Router />
    </>
      
  );
}

export default App;
