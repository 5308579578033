import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./AboutDetails.css";

function AboutDetails({ imageSrc, title, description, isImageFloatRight }) {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div
      className="about_main"
      style={{ flexDirection: isImageFloatRight ? "row-reverse" : "row" }}
      data-aos="fade-right"
      data-aos-delay="300"
    >
      <div style={{ width: 600 }}>
        <img className="about_image" src={imageSrc} alt="aboutImage" />
      </div>
      <div className="about_text" data-aos="fade-left" data-aos-delay="300">
        <h2>{title}</h2>
        <p className="description_color">{description}</p>
        <div style={{ padding: "15px" }}>
          <button className="about_button" type="button">
            Read More
          </button>
        </div>
      </div>
    </div>
  );
}

export default AboutDetails;
