import { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import Python from '../../assets/image/TechnologyImage/python-official.svg';
import ReactLogo from '../../assets/image/TechnologyImage/React-icon.svg';
import NODE from '../../assets/image/TechnologyImage/Node.js_logo.svg';
import AWS from '../../assets/image/TechnologyImage/Amazon_Web_Services_Logo.svg';
import GCP from '../../assets/image/TechnologyImage/google_cloud-ar21.svg';
import AZURE from '../../assets/image/TechnologyImage/microsoft_azure-ar21.svg';
import DIGITAL_OCEAN from '../../assets/image/TechnologyImage/digitalocean-official.svg';
import MYSQL from '../../assets/image/TechnologyImage/mysql-official.svg';
import POSTGRESQL from '../../assets/image/TechnologyImage/Postgresql_elephant.svg';
import ELECTRON from '../../assets/image/TechnologyImage/Electron_Software_Framework_Logo.svg';
import './TechnologySection.css';




function TechnologySection() {

    useEffect(() => {
        Aos.init();
    },[])

    return (
        <div>
            <div className="Footer-module--homepageFooter--SbuAd">
                <h3>Technologies used by Creensight</h3>
                <div className="Footer-module--homepageFooterHeadingLine--N1omg"></div>
            </div>
            <div className="Footer-module--animationLogos--1LFwN">
                <div className="LogoGrid-module--root--1RPUU ">
                    <div data-aos="fade-right" title='python' >
                        <img className='logo' src={Python} alt='python' />
                    </div>
                    <div data-aos="fade-up"  title='React'>
                        <img className='logo' src={ReactLogo} alt='React' />
                    </div>
                    <div data-aos="fade-up"  title='Node'>
                        <img className='logo' src={NODE} alt='Node' />
                    </div>
                    <div data-aos="fade-left"  title='Aws'>
                        <img className='logo' src={AWS} alt='Aws' />
                    </div>
                    <div data-aos="fade-right"  title='Gcp'>
                        <img className='logo' src={GCP} alt='Gcp' />
                    </div>
                    <div data-aos="fade-down"  title='Azure'>
                        <img className='logo' src={AZURE} alt='Azure' />
                    </div>
                    <div data-aos="fade-down"  title='Digital_Ocean'>
                        <img className='logo' src={DIGITAL_OCEAN} alt='Digital_Ocean' />
                    </div>
                    {/* <div data-aos="fade-left"  title='Mysql'>
                        <img className='logo' src={MYSQL} alt='Mysql' />
                    </div> */}
                    {/* <div data-aos="fade-down"  title='Postgresql'>
                        <img className='logo' src={POSTGRESQL} alt='Postgresql' />
                    </div> */}
                    {/* <div data-aos="fade-down"  title='Electron'>
                        <img className='logo' src={ELECTRON} alt='Electron' />
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default TechnologySection;