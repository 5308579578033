import { useEffect } from "react";
import { Helmet } from "react-helmet";
import Aos from "aos";
import "aos/dist/aos.css";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";
import FrequentlyAskedQuestion from "../components/FrequentlyAskedQuestion/FrequentlyAskedQuestion";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import { TextField, Button, Typography, Grid } from "@mui/material";
import ContactImage from "../assets/image/undraw_personal_email_re_4lx7.svg";
import { ContactUsApi } from "../services/contactUs/contactusService";

function Contact() {
  useEffect(() => {
    Aos.init();
  }, []);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [company, setCompany] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const contactInputDetail = {};
  contactInputDetail.fullName = fullName;
  contactInputDetail.email = email;
  contactInputDetail.subject = subject;
  contactInputDetail.company = company;
  contactInputDetail.websiteUrl = websiteUrl;
  contactInputDetail.message = message;

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      ContactUsApi(contactInputDetail).then((res) => {
        if (res.status === 201) {
          setIsLoading(false);
          setFullName("");
          setEmail("");
          setSubject("");
          setCompany("");
          setWebsiteUrl("");
          setMessage("");
          console.log(res.data.message, "contact line 36");
        }
      });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    // <div id="___gatsby">
    // <div style={{ outline: 'none' }} tabindex="-1" id="gatsby-focus-wrapper">
    <div class="Layout-module--root--1cm32">
      <Helmet>
        <title>Contact | Creensight</title>
        <meta charset="UTF-8"/>
        <meta property="og:locale" content="en_US"/>
        <meta property="og:type" content="article"/>
        <meta property="og:title" content="Contact | Creensight"/>
        <meta property="og:url" content="https://creensight.com/contactus"/>
        <meta property="og:site_name" content="Creensight"/>
        <meta property="article:modified_time" content="2024-03-22T05:34:02+00:00"/>
        <meta name="twitter:card" content="summary_large_image"/>
        <link rel="canonical" href="https://creensight.com/contactus" />
      </Helmet>
      <Navbar />
      <div
        class="PageHeader-module--container--AazU9"
        data-aos="fade-up"
        data-test="pageHeader"
      >
        <div class="PageHeader-module--root--IVizT">
          <h2>Hello there!</h2>
          <p class="Contact-module--paragraph--37R24">
            Want us to work on your project? Let’s collaborate!
            <br />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:info@creensight.com"
            >
              INFO@CREENSIGHT.COM
            </a>
          </p>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: 32,
          }}
        >
          <div style={{ flexShrink: 2, flexBasis: 400 }}>
            <img src={ContactImage} alt="" style={{ width: "100%" }} />
          </div>
          <div style={{ flexGrow: 2 }}>
            <div>
              <Typography variant="h4" align="center" mb={2}>
                Contact Us
              </Typography>
            </div>
            <div>
              <form onSubmit={handleSubmit} style={{ textAlign: "center" }}>
                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Name"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      margin="normal"
                      size="sm"
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      margin="normal"
                      required
                      type="email"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Subject"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      margin="normal"
                      required
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Company"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                      margin="normal"
                      required
                    />
                  </Grid>
                </Grid>
                <TextField
                  fullWidth
                  label="Website URL"
                  value={websiteUrl}
                  onChange={(e) => setWebsiteUrl(e.target.value)}
                  margin="normal"
                  required
                />
                <TextField
                  fullWidth
                  label="Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  margin="normal"
                  required
                  multiline
                  rows={4}
                />
                {isLoading ? (
                  <CircularProgress sx={{ color: "#6C63FF" }} />
                ) : (
                  <Button
                    margin="normal"
                    variant="contained"
                    type="submit"
                    sx={{ mt: 3, backgroundColor: "#6C63FF" }}
                  >
                    Submit
                  </Button>
                )}
              </form>
            </div>
          </div>
        </div>
        <div>
          <FrequentlyAskedQuestion />
        </div>
      </div>
      <Footer />
    </div>
    // {/* <div id="gatsby-announcer" style={{ position: 'absolute', top: 0, width: '1px', height: '1px', padding: 0, overflow: 'hidden', clip: 'rect(0, 0, 0, 0)', whiteSpace: 'nowrap', border: 0 }} ariaLive="assertive" ariaAtomic="true"></div> */}
    // </div>
  );
}

export default Contact;
