import { Grid } from "@mui/material";
import Question from "./Question";

function FrequentlyAskedQuestion() {
  return (
    <>
      <div>
        <div style={{ textAlign: "center", padding: "25px" }}>
          <h4>FAQ</h4>
          <h4>FREQUENTLY ASKED QUESTION</h4>
        </div>
      </div>
      <div>
        <Grid rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <Question
              title={"CAN I CHOOSE DEVELOPER MYSELF?"}
              description={
                "Yes. We show you different resume and you can interview yourself a developer. If you feel him/her as a good fit then we can proceed after  basic documentation."
              }
            />
            <Question
              title={"CAN I HIRE DEDICATED DEVELOPER AT MY OFFICE?"}
              description={
                "No. We do not offer on-sight resources for hiring. "
              }
            />
            <Question
              title={"HOW HIRED DEVELOPER WILL GIVE TIME?"}
              description={
                "Whatever number of developers you hire for any amount of time period, they will work dedicatedly only on your project. We have a tracking system where you check all task completion, timeline, and progress."
              }
            />
            <Question
              title={"WOULD I BE INTERACTING WITH DEVELOPER DIRECTLY?"}
              description={
                "Yes. Once you hire any developer, the developer will directly communicate with you and you can discuss all project stuff like estimation, timeline, changes, requirements, etc."
              }
            />
            <Question
              title={"CAN I GET HELP OF DEVELOPER IN DOING R & D?"}
              description={
                "Yes. A freelance developer can help you in doing research on your project requirement and check the feasibility."
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Question
              title={"CAN I HIRE MULTIPLE DEVELOPERS?"}
              description={
                "Yes. You can hire as many developers as per your requirement. You just need to tell us what is your project definition and we can setup everything for you."
              }
            />
            <Question
              title={"CAN I HIRE DEDICATED DEVELOPER FOR LONGER PERIOD?"}
              description={
                "Yes. We recommend you hire dedicated developer or team of developers for a longer period of time if your project is big and complex. That would help you to cost less and get successful work implementation."
              }
            />
            <Question
              title={"WHAT IF I WANT TO HIRE MORE DEVELOPERS AFTERWARDS?"}
              description={
                "You can just raise a query or email us your needs of developers and what expertise you require. Our business development team will reach you out and help you with everything to hire more resources."
              }
            />
            <Question
              title={"WHICH FRAMEWORK IS GOOD FOR WEBSITE DEVELOPMENT?"}
              description={
                "It all depends on your requirement! If you want a scalable website and want to increase features in future which can be complex then you should go with custom web development. If you have simple requirements and no changes in future then WordPress development is good for your business."
              }
            />
            <Question
              title={"WHICH HOSTING IS BEST FOR WEBSITE DEVELOPMENT>"}
              description={
                "The best hosting guide can be checked here in the given link, as it depends on what business needs you have."
              }
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default FrequentlyAskedQuestion;
