import { useState } from "react";
import Card from "@mui/joy/Card";
import { CardContent } from "@mui/material";

function Question({ title, description }) {
  const [flag, setFlag] = useState(false);

  return (
    <Card
      style={{ padding: 12 }}
      variant="outlined"
      sx={{
        minWidth: 300,
        "--Card-radius": (theme) => theme.vars.radius.xs,
      }}
    >
      <CardContent
        orientation="horizontal"
        sx={{ alignItems: "center", gap: 1 }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <h5>{title}</h5>
          <button
            style={{ marginLeft: "auto", width: "30px" }}
            onClick={() => setFlag(!flag)}
          >
            {flag ? "-" : "+"}
          </button>
        </div>
        {flag && (
          <div>
            <p style={{ textAlign: "start", color: "#a49f9f" }}>
              {description}
            </p>
          </div>
        )}
      </CardContent>
    </Card>
  );
}

export default Question;
