import { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import './ServicesTechnology.css';

function ServicesTechnology({ imgSrc, title, description }) {

    useEffect(() => {
        Aos.init();
    }, [])

    return (
    <div className='what_we_do_main'>
        <div data-aos = 'fade-right' data-aos-delay="300">
            <img src={imgSrc} alt='' className='what_we_do_image' />
        </div>
        <div className='what_we_do_text' data-aos = 'fade-left' data-aos-delay="300">
            <div>
                <h2>{title}</h2>
                <p>{description}</p>
            </div>
        </div>
    </div>
    );
}

export default ServicesTechnology;