import React, { useState, useLayoutEffect, useEffect } from "react";
import Logo from '../../assets/image/Logo.png';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { createPortal } from 'react-dom';
import MobileNavigationComponent from "./MobileNavigationComponent";


function Navbar() {

    useEffect(() => {
        Aos.init();
    }, [])

    const [scroll, setScroll] = useState(false);
    const [scrollUpDirectionFlag, setScrollUpDirectionFlag] = useState(true);
    const [oldScrollPosition, setOldScrollPosition] = useState(window.scrollY);
    const [mobileNavigationEnable,setMobileNavigationEnable] = useState(false);

    // console.log(oldScrollPosition);
    // console.log(window.scrollY);

    const scrollAnimation = () => {
        // console.log(oldScrollPosition);
        // console.log(window.scrollY);
        if (oldScrollPosition < window.scrollY) {
            if (window.scrollY > 0) {
                setOldScrollPosition(window.scrollY);
                setScroll(true);
                setScrollUpDirectionFlag(true);
            } else {
                setOldScrollPosition(window.scrollY);
                setScroll(false);
                setScrollUpDirectionFlag(true);
            }
        } else {
            // console.log(window.scrollY);
            if (window.scrollY < 10) {
                setOldScrollPosition(window.scrollY);
                setScroll(false);
                setScrollUpDirectionFlag(true);
            } else {
                setOldScrollPosition(window.scrollY);
                setScroll(false);
                setScrollUpDirectionFlag(false);
            }
        }
    }

    useLayoutEffect(() => {
        // Perform side effects here
        // This code runs after the component has rendered but before the browser paints the screen
        window.addEventListener("scroll", scrollAnimation);
        return () => {
            window.removeEventListener("scroll", scrollAnimation);
            // Cleanup code here (optional)
        };
    }, [oldScrollPosition, scroll, scrollUpDirectionFlag]);


    let scrollAnimationClass = "";
    if (scrollUpDirectionFlag && scroll) {
        scrollAnimationClass = "Header-module--root--1mpSA"
        // .Header-module--hiddenHeader--371Hk";
    } else {
        if (scrollUpDirectionFlag) {
            scrollAnimationClass = "Header-module--root--1mpSA Header-module--transparentBG--1DTtw";
        } else {
            scrollAnimationClass = "Header-module--root--1mpSA";
        }
    }

    const toggleMobileNavigation = () => {
        setMobileNavigationEnable(!mobileNavigationEnable);
    }

    return (
        <header id="root" className={scrollAnimationClass}>
            <div className="Header-module--container--1_Tic">
                <div className="Header-module--left--EKg85" data-aos="zoom-in">
                    <a href="/">
                        <img src={Logo} alt="Logo" />
                    </a>
                </div>
                <div className="Header-module--right--16Rrv" data-aos="zoom-in">
                    {/* <a href="/about" className={`${(window.location.pathname === '/about') ? 'Header-module--selected--3pP8C' : ''}`}>
                        <button type="button" className="Button-module--btn--1lqJX Button-module--opaque--1DMAu Header-module--navButton--1Iqh8">About Us</button>
                    </a>
                    <a href="/services" className={`${(window.location.pathname === '/services') ? 'Header-module--selected--3pP8C' : ''}`} >
                        <button type="button" className="Button-module--btn--1lqJX Button-module--opaque--1DMAu Header-module--navButton--1Iqh8">Services</button>
                    </a>
                    <a href="/blogs" className={`${(window.location.pathname === '/blogs') ? 'Header-module--selected--3pP8C' : ''}`}>
                        <button type="button" className="Button-module--btn--1lqJX Button-module--opaque--1DMAu Header-module--navButton--1Iqh8">Blog</button>
                    </a> */}
                    <a href="/contactus" className={`${(window.location.pathname === '/contactus') ? 'Header-module--selected--3pP8C' : ''}`}>
                        <button type="button" className="Button-module--btn--1lqJX Button-module--opaque--1DMAu Header-module--navButton--1Iqh8">Contact Us</button>
                    </a>
                    <button className="Header-module--hamburger--28FBI" type="button" tabIndex="0" aria-label="Open the menu" onClick={() => toggleMobileNavigation()}></button>
                </div>
            </div>
            {mobileNavigationEnable && 
                createPortal(<MobileNavigationComponent handleClose={toggleMobileNavigation}/>,document.getElementById('ReactModalPortal'))}
        </header>

    )
}
export default Navbar;