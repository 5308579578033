import React, { useEffect } from "react";
import Aos from 'aos';
import 'aos/dist/aos.css';

function MobileNavigationComponent({ handleClose }) {

    useEffect(() => {
        Aos.init();
    }, [])

  return (
    <div
      className="ReactModal__Overlay ReactModal__Overlay--after-open"
      style={{position: 'fixed', inset: 0, backgroundColor: 'rgba(255, 255, 255, 0.75)'}}
    >
      <div
        className="ReactModal__Content ReactModal__Content--after-open Menu-module--modal--3E0IA"
        tabindex="-1"
        role="dialog"
      >
        <nav className="Menu-module--modalRoot--37gO2">
          <main className="Menu-module--container--2otWa Menu-module--hexPattern--1OPEg">
            <article className="Menu-module--subContainer--1o3GP">
              <div className="Menu-module--contentContainer--Sxekq">
                <div className="Menu-module--closeContainer--IhF4Y">
                  <button
                    type="button"
                    aria-label="Close menu"
                    className="Menu-module--closeButton--3V-SX"
                    onClick={() => handleClose()}
                  ></button>
                </div>
                <section
                  className="Menu-module--linkContainer--2HSfV aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <ul className="Menu-module--links--1Fxkj">
                    <li>
                      <a className="" href="/about">
                        About Us
                      </a>
                    </li>
                    <li>
                      <a className="" href="/services">
                        Services
                      </a>
                    </li>
                    <li>
                      <a className="" href="/blogs">
                        Blog
                      </a>
                    </li>
                    <li>
                      <a className="" href="/contactus">
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </section>
                <section className="Menu-module--socialContainer--Z6Hyh">
                  <div className="Menu-module--socialInnerContainer--13yZf">
                    <aside
                      className="Menu-module--collaborate--iUrGQ aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-delay="400"
                    >
                      <h2>Let’s collaborate!</h2>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="mailto:info@creensight.com"
                        >
                          INFO@CREENSIGHT.COM
                        </a>
                      </p>
                    </aside>
                    <aside
                      className="Menu-module--socialLinks--4nmU1 aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-delay="600"
                    >
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/"
                      >
                        Facebook
                      </a>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/company/"
                      >
                        LinkedIn
                      </a>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://github.com/"
                      >
                        Github
                      </a>
                    </aside>
                  </div>
                </section>
              </div>
            </article>
          </main>
        </nav>
      </div>
    </div>
  );
}

export default MobileNavigationComponent;
