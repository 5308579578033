import Axios from 'axios';

export const ContactUsApi = (contactDetails) => {
    const contactUs = process.env.REACT_APP_BACKEND_URL + `site/contact/create`;
    // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjIzLCJ1c2VyX2VtYWlsIjoidW1hckBjcmVlbnNpZ2h0LmNvbSIsImlhdCI6MTY3NjI3NDgzNCwiZXhwIjoxNzA3ODMyNDM0LCJhdWQiOiJHcm9vcGxlIFVzZXIiLCJpc3MiOiJncm9vcGxlLmNvbSIsInN1YiI6InVtYXJAY3JlZW5zaWdodC5jb20ifQ.GZhC1x8pKRioDiOigL-nOMz6lGIubG8BaA8PuGbLNsI"
    const data = {
        name: contactDetails.fullName,
        email: contactDetails.email,
        subject: contactDetails.subject,
        company: contactDetails.company,
        website_url: contactDetails.websiteUrl,
        message: contactDetails.message,

    }
    const headers = {
        'Content-Type': 'application/json',
        // 'Authorization': `${token}`,
    }
    return Axios.post(contactUs, data, { headers: headers });
}