import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Aos from "aos";
import "aos/dist/aos.css";
import { postBlogDetailApi } from "../services/blogs/blogService";
import Navbar from "../components/Navbar/Navbar";

function BlogDetailPage() {
  let { slug } = useParams();
  let blogSlugTitle = slug;

  const [blogDetail, setBlogDetail] = useState({});
  const [blogList, setBlogList] = useState([]);

  useEffect(() => {
    Aos.init();
    postBlogDetailApi(blogSlugTitle).then((res) => {
      setBlogDetail(res.data.data.slug);
      setBlogList(res.data.data.dataList);
    });
  }, [blogSlugTitle]);

  const value = blogList.filter((ele) => {
    return ele.title !== blogDetail.title;
  });

  return (
    <>
      <div class="Layout-module--root--1cm32">
        <Helmet>
          <title>BlogDetail | Creensight</title>
          <meta
            name="description"
            content="Creensight blog is a place where you can find business and technology articles. Check out to get detailed knowledge from the industry experts."
            charSet="utf-8"
          />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="BlogDetail | Creensight" />
          <meta
            property="og:description"
            content="Creensight blog is a place where you can find business and technology articles. Check out to get detailed knowledge from the industry experts."
          />
          <meta
            property="og:url"
            content="https://creensight.com/blog-detail"
          />
          <meta property="og:site_name" content="Creensight" />
          <meta
            property="article:modified_time"
            content="2024-03-22T05:34:02+00:00"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <link rel="canonical" href="https://creensight.com/blog-detail" />
        </Helmet>
        <Navbar />
        <div
          class="PageHeader-module--container--AazU9"
          data-aos="fade-up"
          data-test="pageHeader"
        >
          <div style={{ display: "flex", marginTop: "96px", flexWrap: "wrap" }}>
            <div
              style={{
                maxWidth: "800px",
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                padding: "15px",
                borderRadius: "10px",
                marginTop: "20px",
              }}
            >
              <div
                dangerouslySetInnerHTML={{ __html: blogDetail.content }}
              ></div>
            </div>
            <div
              style={{
                paddingLeft: "25px",
                flexBasis: "50px",
                flexGrow: 2,
                marginTop: "20px",
              }}
            >
              <div
                style={{
                  padding: "20px",
                  position: "sticky",
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                  borderRadius: "10px",
                }}
              >
                <h4>Recent Posts</h4>
                <div>
                  <ul style={{ listStyleType: "none", padding: 0 }}>
                    {value.map((ele) => (
                      <li>
                        <a
                          style={{
                            color: "grey",
                            fontSize: "15px",
                            lineHeight: "2.0",
                            textAlign: "justify",
                          }}
                          href={ele.slug}
                        >
                          {ele.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default BlogDetailPage;
