import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

function Header() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <header className="Home-module--head--1gRJd">
      <h1 className="Home-module--titleHeight--3U4yG" data-aos="fade-up">
        We Design
        <div className="Home-module--words--1FspW">
          <span>Engineering Excellence</span>
          <span>Human Experience</span>
          <span>Digital Products</span>
          <span>Artificial Intelligence</span>
        </div>
      </h1>
      <div
        className="Home-module--description--9izgJ"
        data-aos="fade-up"
        data-aos-delay="300"
        style={{ textAlign: "justify" }}
      >
        <p className="rich-text-helpers-module--paragraph--3gvMP">
         At Creensight, we specialize in providing cutting-edge product-based services tailored to meet the unique needs of our clients. Our team of experts is skilled in Python, ReactJS, AWS, and GCP, ensuring that we deliver innovative solutions that drive business success.
        </p>
        <p className="rich-text-helpers-module--paragraph--3gvMP">
          <h2>What We Do</h2>
            We offer a range of services designed to help businesses achieve their goals through technology:
          <ul>
            <li>
              <strong>Custom Software Development:</strong> We build robust and scalable software solutions using Python and ReactJS, tailored to your specific business needs.  
            </li>
            <li>
              <strong>Cloud Solutions:</strong> Our expertise in AWS and GCP enables us to create reliable, scalable, and secure cloud environments that support your growth and innovation.
            </li>
            <li>
              <strong>Product Design and Strategy:</strong> We work closely with you to understand your vision and turn it into a reality, providing strategic guidance and technical expertise throughout the development process. 
            </li>
            <li>
              <strong>Maintenance and Support:</strong> Our commitment doesn’t end with deployment. We provide ongoing support and maintenance to ensure your systems remain up-to-date and fully operational.
            </li>
          </ul>
        </p>
      </div>
      {/* <div
        className="Home-module--header_button--1cPJA"
        data-aos="fade-right"
        data-aos-delay="500"
      >
        <a href="/services">
          <button
            type="button"
            className="Button-module--btn--1lqJX Button-module--grey--2up4p "
          >
            See Services
          </button>
        </a>
      </div> */}
    </header>
  );
}

export default Header;
