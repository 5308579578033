import React from "react";
import ReactAnimatedCursor from "react-animated-cursor";

function AnimatedCursor() {
  return (
    <ReactAnimatedCursor
      innerSize={14}
      outerSize={16}
      color="108, 99, 255"
      outerAlpha={0.2}
      innerScale={0.9}
      outerScale={5}
      clickables={[
        "a",
        'input[type="text"]',
        'input[type="email"]',
        'input[type="number"]',
        'input[type="submit"]',
        'input[type="image"]',
        "label[for]",
        "select",
        "textarea",
        "button",
        ".link",
        "h1",
        "p",
      ]}
    />
  );
}

export default AnimatedCursor;
